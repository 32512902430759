<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <!-- 根据资产名称和所属公司搜索 -->
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >资产名称：
              <el-input
                class="search_input"
                v-model="parameter.propertyName"
                clearable
                placeholder="请选择资产名称"
                size="medium"
              />
            </span>
            <span
              >所属公司：
              <el-select
                class="search_input"
                v-model="parameter.company"
                clearable
                placeholder="请选择所属公司"
                size="medium"
              >
                <el-option
                  v-for="item in dictData.companyType"
                  :label="item.dictName"
                  :value="item.dictVal"
                  :key="item.dictVal"
                ></el-option>
              </el-select>
            </span>
            <el-button
              type="primary"
              size="medium"
              icon="el-icon-search"
              @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <!-- 新增资产按钮，可逐条增加 可excel导入 -->
            <el-button
              type="primary"
              style="width: 150px"
              icon="el-icon-upload2"
              size="medium"
              @click="input_tx.click()"
              >excel批量新增</el-button
            >
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add" size="medium">新增</el-button>
        </div>
      </div>
      <!-- 表格展示 -->
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" prop="propertyCode" label="资产编号" />
            <el-table-column align="center" prop="propertyType" label="资产分类" />
            <el-table-column align="center" prop="propertyName" label="资产名称" />
            <el-table-column align="center" prop="propertyStatus" label="资产状态">
              <template slot-scope="scope">
                {{ scope.row.propertyStatus | dict(dictData.propertyStatus) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="brand" label="品牌" />
            <el-table-column align="center" prop="model" label="型号" />
            <el-table-column align="center" prop="deviceNumber" label="设备序列号" />
            <el-table-column align="center" prop="managerName" label="管理员" />
            <el-table-column align="center" prop="company" label="所属公司">
              <template slot-scope="scope">
                {{ scope.row.company | dict(dictData.companyType) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="purchaseDate" label="购置时间">
              <template slot-scope="scope"> {{ scope.row.purchaseDate | dateFormat }} </template>
            </el-table-column>
            <el-table-column align="center" prop="userName" label="使用人" />
            <el-table-column align="center" prop="departmentName" label="使用部门" />
            <el-table-column prop="title" fixed="right" align="center" label="操作" width="110">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click="detail(scope.row)"
                  >详情</el-button
                >
                <el-popconfirm
                  icon="el-icon-info"
                  title="确定删除该资产吗？"
                  @confirm="del(scope.row)"
                  @onConfirm="del(scope.row)"
                >
                  <el-button
                    class="btn_danger text_Remove_Bgc"
                    size="mini"
                    slot="reference"
                    type="text"
                  >
                    删除
                  </el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize" />
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- excel导入编号重复展示 -->
    <el-dialog
      title="以下资产编号已存在，新增失败"
      :visible.sync="showAddRepeatDialog"
      :modal-append-to-body="false"
      class="choiceDataCon"
    >
      <div>
        <el-table
          ref="multipleTable"
          row-key="id"
          border
          :data="repeatData"
          :default-expand-all="true"
          tooltip-effect="dark"
          v-loading="loading"
          class="dialog_table"
        >
          <el-table-column align="center" prop="propertyCode" label="资产编号" />
          <el-table-column align="center" prop="propertyType" label="资产分类" />
          <el-table-column align="center" prop="propertyName" label="资产名称" />
        </el-table>
      </div>
    </el-dialog>
    <Edit v-show="showEditDialog" :options="form" :isAdd="isAdd"></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
  },
  data() {
    return {
      parameter: {
        //获取表格
        pageNow: 1,
        pageSize: 10,
        propertyName: null,
        company: null,
      },
      pageSize: 0, //当前页面
      form: {}, //具体某条资产信息
      tableData: [], //资产列表
      total: 0, //资产条数
      loading: false,
      dictData: {}, //字典数据
      typeList: [
        {
          //资产名称
          id: 1,
          name: '1',
        },
      ],
      showEditDialog: false,
      showAddRepeatDialog: false,
      repeatData: [],
      isAdd: true, //弹窗判断
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    //获取资产列表
    this.getData()

    //获取所有资产状况
    this.$api.dict
      .listSysDictData('PROPERTY_STATUS', true)
      .then(res => {
        this.dictData.propertyStatus = res.data
      })
      .catch(err => {
        console.log(err)
      })

    //获取所有公司
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  provide() {
    return {
      re: this.re,
    }
  },
  mounted() {
    //导入文件
    this.input_tx = document.createElement('input')
    this.input_tx.setAttribute('type', 'file')
    this.input_tx.addEventListener('change', e => {
      var files = e.target.files
      var tx = new FormData()
      tx.append('multipartFile', files[0])
      console.log(tx)
      this.$api.assetsControl
        .addBatchPropertyManage(tx)
        .then(res => {
          this.getData()
          this.$forceUpdate()

          if (res.msg != 'success') {
            this.showAddRepeatDialog = true
            this.repeatData = res.data
          }
        })
        .catch(err => {
          this.input_tx.value = ''
          this.$message.error('请上传模板形式的表格')
        })
    })
  },
  methods: {
    // 获取资产列表
    getData() {
      this.loading = true

      this.$api.assetsControl
        .getList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data.records
            // for (var i = 0; i < this.tableData.length; i++) {
            //   this.tableData[i].propertyStatus = this.getStatus(this.tableData[i].propertyStatus);
            //   this.tableData[i].company = this.getCompany(this.tableData[i].company);
            // }

            this.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 资产详情
    detail(row) {
      this.form = row
      this.isAdd = false
      this.showEditDialog = true
    },

    // 新增资产
    add() {
      this.form = {}
      this.isAdd = true
      this.showEditDialog = true
    },

    //关闭弹窗，返回原页面
    re() {
      this.showEditDialog = false
      this.form = {}
      this.getData()
    },

    // 删除资产
    del(row) {
      this.loading = true
      this.$api.assetsControl
        .deletePropertyManage(row.id)
        .then(res => {
          this.loading = false
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 表格行数
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    // 换页
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.parameter.pageNow = 1
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';

.search_input {
  width: 150px;
  margin-right: 10px;
}

/deep/ .el-table .cell {
  white-space: pre-wrap; //表格换行
  padding: 6px 3px; //信息太多，缩小间距
}

.btn_danger {
  padding-left: 5px;
}

.dialog_table {
  margin: 10px 1px;
  width: 700px;
}
</style>
